import CardListProperties from "../../types/CardListProperties"
import React from "react"
import Card from "../card/Card"
import {Grid} from "@material-ui/core"
import {Result} from "antd"

const CardList = (props: CardListProperties) => {

    const {dueCards, emptyFilters} = props

    if (!emptyFilters && (dueCards === undefined || dueCards!.length < 1)) {
        return <Result
            status="warning"
            title="We got nothin'"
            subTitle="No cards with due dates match the filters you've selected."/>
    }

    return (
        <Grid container spacing={1} justifyContent="center">
            {dueCards.map((card) => <Card card={card} lists={props.lists} key={card.id}/>)}
        </Grid>
    )
}

export default CardList