import {createStyles, Theme} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        exportButton: {
            backgroundColor: '#bdbdbd'
        },
        popperMenu: {
            zIndex: 999
        }
    })
)

export { useStyles }