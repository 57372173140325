import React, {useEffect, useState} from "react"
import TrelloDetails from "../../utils/TrelloDetails"
import Card from "trello-shared-resources/dist/types/Card"
import Label from "trello-shared-resources/dist/types/Label"
import CardList from "../card-list/CardList"
import {Result} from "antd"
import FilterAndExport from "../filter-export/FilterAndExport"
import Board from "trello-shared-resources/dist/types/Board"
import {Button} from "@material-ui/core";
import {useStyles} from "./DueNextDialogStyles";
import List from "trello-shared-resources/dist/types/List";

const DueNextDialog = () => {

    const classes = useStyles()

    const [cards, setCards] = useState<Card[]>([])
    const [lists, setLists] = useState<List[]>([])
    const [board, setBoard] = useState<Board>({} as Board)
    const [labels, setLabels] = useState<Label[]>([])
    const [filteredLists, setFilteredLists] = useState<List[]>([])
    const [filteredLabels, setFilteredLabels] = useState<Label[]>([])
    const [checkNoLabel, setCheckNoLabel] = useState<boolean>(false)
    const [errorTitleExporting, setErrorTitleExporting] = useState<string>('')
    const [errorSubtitleExporting, setErrorSubtitleExporting] = useState<string>('')

    /**
     * Initiates loading of required data from the Trello Client Library and returns a group Promise
     * that will indicate when all data has loaded.
     */
    const fetchTrelloData = async () => {
        const trelloContext = TrelloDetails.getTrelloContext()
        setBoard(await trelloContext.board('all'))

        setLists(await trelloContext.lists('all'))

        const cards = await trelloContext.cards('all')
        setCards(cards)

        const labelsByCard = cards.map((card: Card) => card.labels)
        const labels = ([] as Label[]).concat(...labelsByCard)
        const uniqueLabels = labels.filter((elem, index, self) =>
            index === self.findIndex(label => label && label.id === elem.id)
        )
        setLabels(uniqueLabels)
    }

    const filterCardsByDueDate = (cards: Card[]) =>  {
        return cards.filter( (card) =>
            card.due !== undefined && card.due !== null
        )
    }

    const filterCardsByLists = (cards: Card[]) =>  {
        if (filteredLists !== undefined && filteredLists.length > 0) {
            const listsIds: String[] = filteredLists.map((list: List) => list.id)
            return cards.filter((card) => card.idList && listsIds.includes(card.idList))
        }
        return cards
    }

    const filterCardsByLabels = (cards: Card[]) =>  {
        if (checkNoLabel || (filteredLabels !== undefined && filteredLabels.length > 0)) {
            const labelsIds: String[] = filteredLabels.map((label: Label) => label.id)
            return cards.filter((card) => {
                return (checkNoLabel && (card.labels === undefined || card.labels?.length === 0)) ||
                    (card.labels !== undefined && card.labels.length > 0
                        && card.labels.some((label) => labelsIds.includes(label.id)))
            })
        }
        return cards
    }

    const sortCardsByDueDate = (cards: Card[]) =>  {
        return dueCards.sort((card1, card2) => {
            return card1.due! > card2.due! ? 1 : (card1.due! < card2.due! ? -1 : 0)
        })
    }

    const resultBoardWithoutCards = () => {
        return <Result
            status="warning"
            title="Too soon!"
            subTitle="This board doesn’t have any cards yet."/>
    }

    const resultBoardWithoutDueCards = () => {
        return <Result
            status="warning"
            title="Needs a little something..."
            subTitle="No cards on this board currently use Due Date. Add a few due dates to your cards, then come back!"/>
    }

    const closeErrorMessage = () => {
        setErrorTitleExporting('')
        setErrorSubtitleExporting('')
    }

    const resultErrorExporting = (title:string, subtitle: string) => {
        return <Result
            status="error"
            title={title}
            subTitle={subtitle}>
            <Button variant="contained" href="https://productsupport.adaptavist.com/servicedesk/customer/portal/51" target="_blank" className={classes.errorButton}>
                Create support ticket
            </Button>
            <Button variant="contained" color="primary" onClick={closeErrorMessage} className={classes.errorButton}>
                Close
            </Button>
        </Result>
    }

    const areFiltersEmpty = () => {
        return (filteredLists === undefined || filteredLists.length < 1) && (filteredLabels === undefined || filteredLabels.length < 1)
    }

    const areCardsEmpty = (cards: Card[]) => cards === undefined || cards!.length < 1

    useEffect(() => {
        fetchTrelloData()
    }, [])

    if(areCardsEmpty(cards)) {
        return resultBoardWithoutCards()
    }

    let dueCards = filterCardsByDueDate(cards)

    if(areCardsEmpty(dueCards)) {
        return resultBoardWithoutDueCards()
    }

    if(errorTitleExporting !== "") {
        return resultErrorExporting(errorTitleExporting, errorSubtitleExporting)
    }

    dueCards = filterCardsByLists(dueCards)
    dueCards = filterCardsByLabels(dueCards)
    dueCards = sortCardsByDueDate(dueCards)

    return (
        <React.Fragment>
            <FilterAndExport
                lists={lists} filteredLists={filteredLists} setFilteredLists={setFilteredLists}
                labels={labels} filterLabels={filteredLabels} setFilterLabels={setFilteredLabels}
                checkNoLabel={checkNoLabel} setCheckNoLabel={setCheckNoLabel}
                cards={dueCards} board={board}
                setErrorTitleExporting={setErrorTitleExporting}
                setErrorSubtitleExporting={setErrorSubtitleExporting}
            />
            <CardList dueCards={dueCards} lists={lists} emptyFilters={areFiltersEmpty()}/>
        </React.Fragment>
    )

}

export default DueNextDialog
