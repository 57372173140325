import moment from 'moment'
import Card from "trello-shared-resources/dist/types/Card";
import Board from "trello-shared-resources/dist/types/Board";
import CardToExport from "../../types/CardToExport";

import * as Papa from 'papaparse'
import SaveFile from "trello-shared-resources/dist/modules/file/SaveFile";
import List from "trello-shared-resources/dist/types/List";
import ContentTransformers from "trello-shared-resources/dist/types/ContentTransformers";
import ContentExpanders from "trello-shared-resources/dist/types/ContentExpanders";
import {labelsTransformer, membersTransformer} from "trello-shared-resources/dist/modules/utility/Transformers";
import {listNameExpander} from "trello-shared-resources/dist/modules/utility/Expanders";
import DataFormatterImpl from "./DataFormatterImpl";

class CSVExport {

    // --== File Format ==--
    private static FILE_TYPE = 'text/csv;charset=utf-8;'

    /**
     * Export given list of cards to CSV file
     * @param {*} listCards list of Card to export
     * @param {*} lists list of List to get card list name
     * @param {*} board Board info to get board name
     */
    export(listCards: Card[], lists: List[], board: Board, setErrorTitleExporting: (errorExporting: string) => void, setErrorSubtitleExporting: (errorExporting: string) => void) {
        try {
            const dataFormatter = new DataFormatterImpl(
                ["id", "url", "idList", "name", "labels", "due", "members", "desc"], // <- Fields that we want to use from the cards
                // Fields that need transformational formatting (i.e. Objects / complex types)
                {
                    members: membersTransformer,
                    labels: labelsTransformer
                } as ContentTransformers,
                // Fields that need expansion (i.e. compositing data from other sources)
                {
                    idList: listNameExpander
                } as ContentExpanders
            )
            // 2. Compile a list of all of the cards in those lists
            const filteredCardData = dataFormatter.getCardDataFromLists(listCards, lists, board);

            // 3. Generate CSV output using the Papa.unparse(data[, config])
            const csvOutput = this.createCsvFromCards(filteredCardData);

            // 4. Save to file using SaveFile module
            const saveFile = new SaveFile(CSVExport.FILE_TYPE)
            const currentDate = moment().format('YYYYMMDD');
            const fileName = board.name + ' ' + currentDate + '.csv'
            saveFile.saveDataToFile(fileName, csvOutput)
        } catch (e) {
            setErrorTitleExporting("Can't generate the CSV!")
            setErrorSubtitleExporting("Please try again. If the issue persists, contact our support team so we can have a closer look.")
        }
    }

    /**
     * Converts the given array of JS Objects into a CSV String that can be written to a file.
     * @param {*} listOfCardData A list of card data that we want to convert to CSV format.
     */
    createCsvFromCards(listOfCardData: CardToExport[]) {
        return Papa.unparse(listOfCardData);
    }
}

export default CSVExport;