import React from 'react'
import ReactDOM from 'react-dom'
// Import the views your app loads
import Connector from './views/Connector'

import { appDetailsState, LoadAnalytics, Onboarding, Settings, writeLog, } from 'trello-shared-resources'
import ErrorComponent from 'trello-shared-resources/dist/components/onboarding/error/ErrorComponent'
// Globally load the ant design css
import 'antd/dist/reset.css'
// Load the main Orah CSS
import './css/main.css'
import 'trello-shared-resources/dist/assets/fonts/font.css'
import LicensedDueNext from './views/LicensedDueNext'

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorSection } from 'trello-shared-resources/dist/components/onboarding/error/ErrorSection'

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require('query-string')
const parsedQueryString = queryString.parse(window.location.search)
let iframePassedAppType = parsedQueryString['apptype']
writeLog('Iframe was passed apptype : ' + iframePassedAppType)

LoadAnalytics()

function getComponentToRender(iframePassedAppType: string) {
    switch (iframePassedAppType) {
        case 'onboarding':
            // This automatically loads when your app is installed
            return <Onboarding context={appDetailsState}/>
        case 'settings':
            // This is the settings page for your app
            return <Settings context={appDetailsState}/>
        case 'due-next':
            // This loads the Due Next power-up
            return <LicensedDueNext/>
        case 'error':
            return <ErrorComponent/>
        default:
            // We not pass a querystring param for the App Connector
            // This connector is used to instantiate your app's capabilities
            return <Connector/>
    }
}

ReactDOM.render(<ErrorBoundary fallbackRender={({error, resetErrorBoundary}) =>
        <ErrorSection errorMessage={error.message} setErrorMessage={resetErrorBoundary}
                      smallWindow={false}/>}> {getComponentToRender(iframePassedAppType)} </ErrorBoundary>
    , document.getElementById('root')
)