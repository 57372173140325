import React, {useState} from 'react'
import {Box, FormControl, Grid, Input, ListItemText, MenuItem, Select} from '@material-ui/core'
import ListSelectorProperties from '../../types/ListSelectorProperties'
import {Checkbox} from 'antd'
import {useStyles} from './ListSelectorStyles'
import {TrackActionEvent} from 'trello-shared-resources/dist/index'
import TrelloDetails from '../../utils/TrelloDetails'
import List from 'trello-shared-resources/dist/types/List'

const ListSelector = (props: ListSelectorProperties) => {

    const classes = useStyles()

    const [checkedAll, setCheckedAll] = useState<boolean>(false)
    const {filteredLists, setFilteredLists, lists} = props

    const handleSelectLists = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValues = event.target.value as String[]
        if (selectedValues.includes('selectAllLists')) {
            selectAllLists()
        } else {
            const selectedLists: List[] = lists.filter((list: List) => selectedValues.includes(list.id))
            setFilteredLists(selectedLists)
            setCheckedAll(selectedLists.length === lists.length)
        }
        const context = TrelloDetails.getContext()
        TrackActionEvent('Filter Cards', context, {
            board_id: context.board,
            type: 'list'
        })
    }

    const selectAllLists = () => {
        setFilteredLists((checkedAll ? [] : lists))
        setCheckedAll(!checkedAll)
    }

    return (
        <Grid item xs={5}>
            <Box pl={4} pb={2}>
                <FormControl className={classes.formControl}>
                    <Select
                        labelId="lists-filter-name-label"
                        id="lists-filter-name"
                        multiple
                        displayEmpty
                        value={filteredLists.map(filteredList => filteredList.id)}
                        renderValue={() => filteredLists.length > 0 ? `${filteredLists.length} list(s) selected` : 'Select lists to filter'}
                        onChange={handleSelectLists}
                        input={<Input/>}
                        MenuProps={{variant: 'menu'}}
                    >
                        <MenuItem
                            selected={checkedAll}
                            value="selectAllLists"
                            className={classes.selectAllOption}
                        >
                            <Box mr={1}>
                                <Checkbox checked={checkedAll}/>
                            </Box>
                            <Box>
                                <ListItemText primary={checkedAll ? 'Select None' : 'Select all'}/>
                            </Box>
                        </MenuItem>
                        {lists.map((list) => (
                            <MenuItem key={list.id} value={list.id}>
                                <Box mr={1}>
                                    <Checkbox checked={filteredLists.includes(list)} className="select-checkbox"/>
                                </Box>
                                <Box>
                                    <ListItemText primary={list.name}/>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    )
}

export default ListSelector