import {createStyles, Theme} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorButton: {
            margin: '0 5px 0 5px'
        }
    })
)

export { useStyles }