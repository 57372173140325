import React, {useState} from "react"
import {Box, FormControl, Grid, Input, ListItemText, MenuItem, Select} from "@material-ui/core"
import {Checkbox} from "antd"
import LabelSelectorProperties from "../../types/LabelSelectorProperties"
import Label from "trello-shared-resources/dist/types/Label"
import {useStyles} from "./LabelSelectorStyles"
import {TrackActionEvent} from 'trello-shared-resources/dist/index'
import TrelloDetails from "../../utils/TrelloDetails";
import List from "trello-shared-resources/dist/types/List";

const LabelSelector = (props: LabelSelectorProperties) => {

    const classes = useStyles()

    const [checkedAll, setCheckedAll] = useState<boolean>(false)
    const {filterLabels, setFilterLabels, labels, checkNoLabel, setCheckNoLabel} = props

    const handleSelectLabels = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValues = event.target.value as String[]
        if (selectedValues.includes('selectAllLabels')) {
            selectAllLabels()
        } else {
            let newCheckNoLabel = checkNoLabel
            if(selectedValues.includes('selectNoLabel')) {
                newCheckNoLabel = !checkNoLabel
                setCheckNoLabel(newCheckNoLabel)
            }
            const selectedLabels: List[] = labels.filter((label: Label) => selectedValues.includes(label.id))
            setFilterLabels(selectedLabels)
            setCheckedAll(selectedLabels.length === labels.length && newCheckNoLabel)
        }
        const context = TrelloDetails.getContext()
        TrackActionEvent('Filter Cards', context, {
            board_id: context.board,
            type: 'label'
        })
    }

    const selectAllLabels = () => {
        setFilterLabels((checkedAll ? [] : labels))
        setCheckNoLabel(!checkedAll)
        setCheckedAll(!checkedAll)
    }

    return (
        <Grid item xs={5}>
            <Box pb={2}>
                <FormControl className={classes.formControl}>
                    <Select
                        labelId="labels-filter-name-label"
                        id="labels-filter-name"
                        multiple
                        displayEmpty
                        value={ checkNoLabel ? ['-1', ...filterLabels.map(filteredLabel => filteredLabel.id)] : filterLabels.map(filteredLabel => filteredLabel.id)}
                        renderValue={() => (filterLabels.length > 0 || checkNoLabel) ? `${filterLabels.length + (checkNoLabel ? 1 : 0)} label(s) selected` : 'Select labels to filter'}
                        onChange={handleSelectLabels}
                        input={<Input/>}
                        MenuProps={{variant:'menu'}}
                    >
                        <MenuItem
                            selected={checkedAll}
                            value="selectAllLabels" className={classes.selectAllOption}
                        >
                            <Box mr={1}>
                                <Checkbox checked={checkedAll}/>
                            </Box>
                            <Box>
                                <ListItemText primary={checkedAll ? "Select None" : "Select all"}/>
                            </Box>
                        </MenuItem>

                        <MenuItem
                            selected={checkNoLabel}
                            value="selectNoLabel"
                        >
                            <Box mr={1}>
                                <Checkbox checked={checkNoLabel} onClick={() => setCheckNoLabel(!checkNoLabel)}/>
                            </Box>
                            <Box>
                                <ListItemText primary="No label"/>
                            </Box>
                        </MenuItem>
                        {labels.map((label) => (
                            <MenuItem key={label.id} value={label.id}>
                                <Box mr={1}>
                                    <Checkbox checked={filterLabels.includes(label)} className="select-checkbox" />
                                </Box>
                                <Box>
                                    <ListItemText primary={label.name}/> ({label.color ? label.color : "no color"})
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    )
}

export default LabelSelector