import React from 'react'
import TrelloDetails from '../../utils/TrelloDetails'
import {renderTrelloContextMissing} from 'trello-shared-resources/dist'
import DueNextProperties from "../../types/DueNextProperties"
import DueNextDialog from "../due-next-dialog/DueNextDialog"

const DueNext = (dueNextProperties: DueNextProperties) => {
  TrelloDetails.createInstance(dueNextProperties.licenseDetails)

  if (!TrelloDetails.getTrelloContext()) {
    return renderTrelloContextMissing()
  }

  if (!TrelloDetails.getIsAuthorized() || !TrelloDetails.getIsLicensed()) {
    TrelloDetails.getLicenseDetails().trelloIframeContext.modal({
      url: './index.html?apptype=error',
      fullscreen: true,
      resizable: false,
      title: 'Due Next by Kolekti',
      accentColor: '#EBEDF0'
    })
    return (<div>Loading...</div>)
  }

  return (
    <div className="popup__wrapper">
      <DueNextDialog/>
    </div>
  )
}

export default DueNext
