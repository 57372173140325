import {makeStyles} from "@material-ui/core/styles"
import {createStyles, Theme} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dueFuture: {
            backgroundColor: '#61BD4F',
            textTransform: 'none',
            color: 'white'
        },
        dueToday: {
            backgroundColor: '#ffbf00',
            textTransform: 'none',
            color: 'white'
        },
        duePassed: {
            backgroundColor: '#EB5A46',
            textTransform: 'none',
            color: 'white'
        },
        listName: {
            fontWeight: 'bold',
            wordWrap: 'break-word'
        },
        cardName: {
            color: 'black',
            wordWrap: 'break-word'
        }
    })
)

export { useStyles }
