import {createStyles, Theme} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 175,
            maxWidth: '100%',
        },
        selectAllOption: {
            borderBottom: '1px solid grey'
        }
    })
)

export { useStyles }