import {Box, Grid, Typography} from "@material-ui/core"
import ListSelector from "../list-selector/ListSelector"
import React from "react"
import FilterAndExportProperties from "../../types/FilterAndExportProperties"
import LabelSelector from "../label-selector/LabelSelector"
import Export from "../export/Export"

const FilterAndExport = (props: FilterAndExportProperties) => {

    return (
        <Grid container spacing={1} direction="row"
              justifyContent="flex-start"
              alignItems="center">
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
                <Typography component="h3" gutterBottom align="left">
                    <Box fontWeight="fontWeightBold" >
                        Find upcoming and overdue cards
                    </Box>
                </Typography>
            </Grid>
            <ListSelector {...props}/>
            <LabelSelector {...props}/>
            <Export {...props}/>
        </Grid>
    )

}

export default FilterAndExport