import React, {useState} from 'react'
import {Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core'
import CSVExport from '../../modules/export/CSVExport'
import ExportProperties from '../../types/ExportProperties'
import CloudDownloadSharpIcon from '@material-ui/icons/CloudDownloadSharp'
import {useStyles} from './ExportStyles'
import exportToPDF from '../../modules/export/PDFExport'
import TrelloDetails from '../../utils/TrelloDetails'
import {TrackActionEvent} from 'trello-shared-resources/dist/index'

const Export = (props: ExportProperties) => {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const {setErrorTitleExporting, setErrorSubtitleExporting} = props
    const context = TrelloDetails.getContext()

    const downloadExcel = () => {
        handleCloseMenu()
        TrackActionEvent('File Download', context,
            {
                board_id: context.board,
                format: 'csv'
            }
        )
       new CSVExport().export(props.cards, props.lists, props.board, setErrorTitleExporting, setErrorSubtitleExporting)
    }

    const downloadPDF = () => {
        handleCloseMenu()
        TrackActionEvent('File Download', context,
            {
                board_id: context.board,
                format: 'pdf'
            }
        )
        exportToPDF(props.cards, props.lists, props.board, setErrorTitleExporting, setErrorSubtitleExporting)
    }

    const handleCloseMenu = () => setOpen(false)

    return <Grid item xs={2}>
        <Box pr={2} pb={2}>
            <Button aria-controls={open ? 'menu-list-grow' : undefined}
                    variant="contained"
                    ref={anchorRef}
                    className={classes.exportButton}
                    aria-haspopup="true"
                    onClick={() => setOpen(!open)}
                    disabled={props.cards === undefined || props.cards.length < 1}
            >
                <CloudDownloadSharpIcon fontSize="small"/>
            </Button>
            <Popper open={open} role={undefined}
                    transition
                    disablePortal
                    anchorEl={anchorRef.current}
                    placement="bottom-end"
                    className={classes.popperMenu}
            >
                {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps} >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    <MenuItem onClick={downloadExcel}>Download Excel</MenuItem>
                                    <MenuItem onClick={downloadPDF}>Download PDF</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    </Grid>
}

export default Export