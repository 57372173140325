import React, {useEffect} from 'react'
import {Skeleton} from 'antd'
import {LicenseDetailsContext, LicenseDetailsProvider, PageView,} from 'trello-shared-resources/dist/index'
import DueNext from "../components/due-next/DueNext";

const LicensedDueNext = () => {
  useEffect(() => {
    PageView('DueNext')
  }, [])

  return (
    <LicenseDetailsProvider>
      <LicenseDetailsContext.Consumer>
        {(licenseDetails: any) => {
          if (licenseDetails.isLoading) {
            return <Skeleton active={true} />
          }
          return <DueNext licenseDetails={licenseDetails} />
        }}
      </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
  )
}

export default LicensedDueNext
