import DataFormatter from "trello-shared-resources/dist/modules/formatting/DataFormatter";
import Card from "trello-shared-resources/dist/types/Card";
import List from "trello-shared-resources/dist/types/List";
import Board from "trello-shared-resources/dist/types/Board";
import TrelloData from "trello-shared-resources/dist/types/TrelloData";

/**
 * Alters Data from Trello into a format that is desirable for exportation.
 */
class DataFormatterImpl extends DataFormatter {

    /**
     * Given an array of Trello lists we build a list of card data 'objects' that we want to be converted to CSV. These
     * objects are pre-formatted to include only the fields we want to see in the final output.
     *
     * Fields that are used are dictated by the static FIELDS_TO_OUTPUT at the top of the class.
     *
     * @param {*} listCards The cards we want to extract data from.
     * @param {*} lists The lists we want to extract cards from.
     * @param {*} board The board that we use to extrapolate the custom fields from.
     * @returns An array of 'objects' containing the the data we will convert to CSV format.
     */
    getCardDataFromLists(listCards: Card[], lists: List[], board: Board) {
        return listCards.map(card => {
            let cardDataObject: any = {}
            const trelloData: TrelloData = {
                board: board,
                list: lists.find(list => list.id === card.idList),
                card: card
            }

            this._loadCardDisplayPropertiesIntoDataObject(trelloData, cardDataObject)
            this._loadExpandedPropertiesIntoDataObject(trelloData, cardDataObject);
            return cardDataObject
        })

    }
}

export default DataFormatterImpl;